import React from "react";
import { Layout } from "../components/layouts/Layout";
import { Nav } from "../components/layouts/Nav";
import { Footer } from "../components/layouts/Footer";

export default function FaqPage() {
  return (
    <Layout title="Besoin d'aide ?">
      <Nav />
      <section className="section">
        <div className="container content">
          <h1 className="title is-1">Besoin d'aide ?</h1>
          <p>
            Cette application est développée dans le but d'aider les gens dans
            la gestion des setlists.
            <br />
            Je suis ouvert aux propositions et aux demandes d'aides, si vous
            rencontrez des problèmes ou améliorations sur l'application.
          </p>
          <p>
            <a
              href="mailto:baptiste.lecocq+setlist@gmail.com"
              className="button is-primary"
            >
              Contacter Baptiste
            </a>
          </p>
        </div>
      </section>
      <Footer />
    </Layout>
  );
}
